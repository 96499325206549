
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import {
  HashLocationStrategy,
  LocationStrategy,
  DatePipe
} from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { PrivacyNoticeComponent } from './core/components/privacy-notice/privacy-notice.component';
import { NonCompatibleComponent } from './core/components/non-compatible/non-compatible.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { FilterModalComponent } from './core/components/filter-modal/filter-modal.component';
import { HeaderComponent } from './core/components/header/header.component';
import { PrivacyTermsComponent } from './public/privacy-terms/privacy-terms.component';
import { LogoutComponent } from './core/components/logout/logout.component';
import { LoginComponent } from './core/components/login/login.component';
import { ToastIdleTimeoutComponent, FormatTimePipe } from './core/components/toast-idle-timeout/toast-idle-timeout.component';
import { ExternRouteComponent } from './core/components/extern-route/extern-route.component';
import { HttpInterceptorService } from './core/services/http-interceptor.service';
import { DialogComponent } from './public/dialog/dialog.component';

// tslint:disable-next-line: max-line-length
// tslint:disable-next-line: max-line-length
import { BenefitsDetailModalComponent } from './public/benefits/modules/list/components/benefits-detail-modal/benefits-detail-modal.component';
import { AppInitService } from './core/services/app-init.service';
import { PolicyModalComponent } from './public/dashboard/components/policy-modal/policy-modal.component';
import { NotificationComponent } from './core/components/notification/notification.component'
import { ConfirmBenefitsDialogComponent } from './public/benefits/modules/review/review.component';
import { BenefitService } from './public/benefits/services/benefit.service';

// Translate Modules
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import { SharedModule } from './core/shared/shared.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DocumentLibraryPageComponent } from './public/document-library-page/document-library-page.component';
import { CoBrowsingTimerComponent } from './core/components/co-browsing-timer/co-browsing-timer.component';

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  };
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LogoutComponent,
        ExternRouteComponent,
        PrivacyNoticeComponent,
        NonCompatibleComponent,
        FooterComponent,
        FilterModalComponent,
        HeaderComponent,
        PrivacyTermsComponent,
        NotificationComponent,
        ToastIdleTimeoutComponent,
        FormatTimePipe,
        NotificationComponent,
        DocumentLibraryPageComponent,
        CoBrowsingTimerComponent
    ],
    imports: [
        OAuthModule.forRoot(),
        NgIdleKeepaliveModule.forRoot(),
        SharedModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FlexLayoutModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        MatButtonModule,
        MatSidenavModule,
        MatExpansionModule,
        MatIconModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatDialogModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatToolbarModule,
        MatListModule,
        MatStepperModule,
        MatRadioModule,
        MatGridListModule,
        MatDatepickerModule,
        MatCardModule,
        FormsModule,
        MatSnackBarModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatTabsModule,
        MatSortModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatNativeDateModule,
        MatDividerModule,
        // MaterialModule,
        NgxSpinnerModule,
        MatSlideToggleModule,
        MatBottomSheetModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule, // I must be last!! https://angular.io/guide/router#module-import-order-matters
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        AppInitService,
        BenefitService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [
                AppInitService
            ],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        DatePipe,
        CookieService,
        BenefitService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
